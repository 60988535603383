<template>
	<div class="publish" v-if="!isShowHuaTi">
		<TOP title="发布动态" :isBack="true" :history="history"></TOP>
		<van-button class="comfirmBtn" @click="publishFun" v-preventReClick="1500">发布</van-button>
		<div class="publishBox">
			<div class="textareaDiv">
				<van-field v-model="message" rows="1" autosize type="textarea" placeholder="说点什么吧" />
			</div>
			<div class="uploadImgDiv">
				<van-uploader v-model="fileList" :after-read="afterRead" :max-count="9" :before-read="beforeRead"
					upload-icon="plus" :before-delete="beforeDelete" :max-size="1024*1024*5" @oversize="onOversize" />
				<!-- :max-size="500 * 1024":before-read="beforeRead"上传前处理 -->
			</div>
			<div class="selectHuatiBox">
				<div class="selectHuatiBtn" @click="selectHuati">#选择话题? </div>
				<div class="selectHuatiDes">听说添加话题会得到更多赞哦</div>
			</div>
			<div class="huatiList">
				<span v-if="huatiType">{{huatiType}}</span>
			</div>
		</div>

	</div>	
	<SELECTHUATI :isShow="isShowHuaTi"></SELECTHUATI>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import {
		Field,
		CellGroup,
		Uploader,
		Toast,
		Button
	} from 'vant';
	import TOP from '@/components/top/top.vue';
	import request from '@/service/request';
	import SELECTHUATI from '@/components/publish/selectHuati.vue'
	// import preventReClick from'@/util/v-repeat';
	export default defineComponent({
		components: {
			TOP,
			[Field.name]: Field,
			[CellGroup.name]: CellGroup,
			[Uploader.name]: Uploader,
			SELECTHUATI,
			[Button.name]:Button
		},
		
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #f8faf5, #f8faf5);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		data() {
			return {
				mypic: [],
				subject: '',
				message: '',
				typeid: '',
				fileList: [],
				huatiType: '',
				isShowHuaTi: false
			}
		},
		created() {
			this.typeid = this.$route.query.typeid;
			this.huatiType = this.$route.query.huatiType;
			this.history=this.$route.query.history;
		},
		methods: {
			selectHuatiDone(typeid, huatiType) {
				this.huatiType = huatiType;
				this.typeid = typeid;
				this.closeHuatiDone();
			},
			closeHuatiDone() {
				this.isShowHuaTi = false;
			},
			publishFun() {
				if (!this.message) {
					Toast("请输入发布内容！")
					return;
				}
				if (!this.typeid) {
					Toast("请选择话题！")
					return;
				}
				var mypic = '';
				var len = this.mypic.length;
				if (len > 0) {
					for (var i = 0; i < len; i++) {
						mypic += this.mypic[i];
						if (i < len - 1) {
							mypic += ','
						}
					}
				}
				request.apiAxios({
					url: request.NEWTHREAD,
					method: 'post',
					data: {
						subject: this.subject,
						message: this.message,
						typeid: this.typeid,
						mypic: mypic
					}
				}).then(res => {
					if (res.status) {
						console.log(res)
						Toast({
							message:res.message,
							duration:3500
						});
						setTimeout(()=>{
							this.$router.replace(this.history);
						},3600)
					} else {
						Toast({
							message:res.message,
							duration:3500
						})
					}
				}).catch(err => {
					console.log(err);
					Toast("发布失败！")
				})
			},
			afterRead(file) {
				file.status = 'uploading';
				file.message = '上传中...';
				request.submitPic({
					url: request.UPLOADIMG,
					data: file
				}).then(res => {
					if(res.status){
						file.status = "done";
						file.message = "上传成功";
						this.mypic.push(res.data.picurl);
						console.log(this.mypic,'上传成功后');
					}else{
						file.status = "failed";
						file.message = res.message;
					}
					
				}).catch(err => {
					console.log(err);
					file.status = 'failed';
					file.message = '上传失败';
				})
			},
			selectHuati() {
				this.isShowHuaTi = true;
			},
			beforeDelete(file, detail){
				//let vm = this;
				this.fileList.splice(detail.index,1);
				this.mypic.splice(detail.index,1);
				console.log(this.mypic,'删除成功后');
			},
			onOversize(file){
				 Toast('文件大小不能超过 2M');
			}
		},
		setup() {

			const beforeRead = (file) => {
				// if (file.type !== 'image/jpeg') {
				//   Toast('请上传 jpg 格式图片');
				//   return false;
				// }
				return true;
			};
			return {
				beforeRead

			}
		}
	});
</script>

<style scoped="scoped" lang="less">
	.comfirmBtn {
		position: fixed;
		right: 0.2rem;
		top: 0.13rem;
		width: 1.22rem;
		height: 0.64rem;
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.32rem;
		font-size: 0.26rem;
		font-family: SimHei;
		font-weight: 400;
		color: #4A898B;
		text-align: center;
		line-height: 0.64rem;
		z-index: 10000;
		padding: 0;
	}

	.textareaDiv {
		margin-bottom: 0.3rem;
		min-height: 3rem;
	}

	.uploadImgDiv {
		padding: 0.1rem 0.2rem 0.2rem;
	}

	.selectHuatiBox {
		display: flex;
		padding: 0 0.2rem;
		align-items: center;
		.selectHuatiBtn {
			flex: none;
			padding: 0.14rem 0.26rem;
			background: #DBDED4;
			border-radius: 0.28rem;
			text-align: center;
			font-size: 0.24rem;
			color: #5D6262;
		}
		.selectHuatiDes {
			flex: auto;
			color: #7D8383;
			font-size: 0.24rem;
			text-align: right;
		}
	}

	.huatiList {
		padding: 0.2rem;

		span {
			padding: 0.14rem 0.26rem;
			background: #DBDED4;
			border-radius: 0.28rem;
			text-align: center;
			font-size: 0.24rem;
			display: inline-flex;
		}

		color:#5D6262;
	}
</style>
<style lang="less">
	.publishBox {
		.textareaDiv {
			.van-cell {
				background: none;

				textarea {
					font-size: 0.3rem;
					color: #7D8383;
				}
			}
		}

		.van-uploader__upload {
			background: #DBDED4;
			border-radius: 0.14rem;

			.van-uploader__upload-icon {
				color: #F8FAF5;
			}
		}
	}
</style>
