<template>
	<div class="selectHuati" v-if="isShow">
		<TOP title="选择话题" :isBack="true" from="selectHuati"></TOP>
		<!-- <img class="backBtn" src="@/assets/images/back_w.png" @click="closeFun" /> -->
		<div class="comfirmBtn" @click="comfirmSelect">确认</div>
		<div class="huatiList">
			<van-list v-model:loading="loading" :finished="finished" :finished-text="finishTxt" @load="loadData">
				<van-radio-group v-model="typeid">
					<van-radio  checked-color="#F7DE7D" v-for="(item,index) in listData" :key="index" :name="item.typeid+','+item.name">
						<div class="huatiDetail">
							<div class="huatiInfoBox">
								<img class="huatiIcon" src="../../assets/images/huati_icon.png" />
								<div class="huatiinfo">
									<div class="huatiName">#{{item.name}}</div>
									<div class="huatiDes">遇到问题可以在这里找</div>
								</div>
							</div>
					 	<div class="huatiNum" v-if="false">1</div>
						</div>
					</van-radio>
				</van-radio-group>
			</van-list>
		</div>
		
	</div>
</template>

<script>
	import {
		defineComponent
	} from 'vue';
	import TOP from '@/components/top/top.vue';
	import {
		RadioGroup,
		Radio,
		List,
		Toast
	} from 'vant';
	import request from '@/service/request'
	export default defineComponent({
		components: {
			TOP,
			[RadioGroup.name]: RadioGroup,
			[Radio.name]: Radio,
			[List.name]: List
		},
		mounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(0deg, #EDEDD0, #DBEFE7);");
		},
		unmounted() {
			document.querySelector("body").setAttribute("style",
				"background: linear-gradient(#95C5B5, #64A4A4) no-repeat;");
		},
		data() {
			return {
				loading: false, //是否处于加载状态，加载过程中不触发 load 事件
				listData: [],
				page: 1,
				finishTxt: "没有更多了",
				finished: true,
				typeid: ''
			}
		},
		props:{
			isShow:{
				type:Boolean,
				default:false
			}
		},
		created() {
			this.getData();
		},
		methods: {
			loadData() {
				this.getData();
			},
			getData() {
				request.apiAxios({
					method: 'post',
					url: request.GETTYPES
				}).then((res) => {
					if (res.status) {
						if (res.data.length > 0) {
							//if (this.listData.length == 0) {
								this.listData = res.data;
							// } else {
							// 	this.listData = this.listData.concat(res.data);
							// }
							// this.finished = false;
						} else {
							//this.finished = true;
						}
						// this.page++;
					}
					//this.loading = false;
				}).catch((err) => {
					console.log(err);
					//this.finished = true;
				})
			},
			comfirmSelect(){
				console.log(this.typeid);
				if(!this.typeid){
					Toast("请选择话题！");
					return;
				}
				var arr=this.typeid.split(',');
				this.$parent.selectHuatiDone(arr[0],arr[1]);
			},
			closeFun(){
				this.$parent.closeHuatiDone();
			}
		},

	});
</script>

<style lang="less" scoped="scoped">
	.backBtn {
		position: fixed;
		width: 0.33rem;
		height: 0.31rem;
		left: 0.24rem;
		top: 0.3rem;
		margin-right: 0.4rem;
		z-index:10000;
	}
	.selectHuati{
		position: fixed;
		top: 0;
		bottom:0;
		right:0;
		left:0;
		z-index:1;
		overflow-y:auto;
	}
	.comfirmBtn {
		position: fixed;
		right: 0.2rem;
		top: 0.13rem;
		width: 1.22rem;
		height: 0.64rem;
		background: #F8FAF5;
		box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
		border-radius: 0.32rem;
		font-size: 0.26rem;
		font-family: SimHei;
		font-weight: 400;
		color: #4A898B;
		text-align: center;
		line-height: 0.64rem;
		z-index: 10000;
	}

	.huatiList {
		padding: 0.2rem;

		.huatiDetail {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}

		.huatiInfoBox {
			display: flex;
			flex: auto;
			color: #5D6262;
		}

		.huatiIcon {
			width: 1.06rem;
			height: 1.06rem;
			margin-right: 0.2rem;
			flex: none;
		}

		.huatiinfo {
			flex: auto;
			align-items: center;
			display: flex;
			justify-content: space-around;
			flex-direction: column;
			text-align: left;
			line-height: 0.45rem;

			&>div {
				width: 100%;
				height: 0.45rem;
				overflow: hidden;
			}

			margin-right:0.2rem;
		}

		.huatiNum {
			background-image: -webkit-linear-gradient(bottom, red, #F34701, #FB8A0F);
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			font-size: 0.25rem;
			width: 1rem;
			text-align: right;
			margin-top: 0.7rem;
			margin-right: 0.1rem;
			flex: none;
		}
	}
</style>
<style lang="less">
	.huatiList {
		.van-radio {
			width: 100%;
			background: #F8FAF5;
			box-shadow: 0px 1px 2px 0px rgba(161, 161, 136, 0.36);
			border-radius: 0.14rem;
			padding: 0.16rem 0.2rem;
			position: relative;
			margin-bottom: 0.2rem;

			.van-radio__icon {
				position: absolute;
				right: 0.22rem;
				top: 0.38rem;
			}

			.van-radio__label {
				width: 100%;
			}
		}
	}
</style>
