<template>
	<div class="topBox">
		<div class="topDiv">
			<div class="backBtn"  v-if="isBack">
				<img  src="@/assets/images/back_w.png"  @click="gohistory"/>
			</div>			
			<span>{{title}}</span>
			<img v-if="isSearch" class="searchBtn" @click="goSearch" src="@/assets/images/top_search.png" />
		</div>
	</div>
	
</template>

<script>
	import {
		defineComponent
	} from 'vue';

	export default defineComponent({
		name: 'test',
		props: {
			title: String,
			isSearch: {
				type: Boolean,
				default: false
			},
			isBack:{
				type: Boolean,
				default: false
			},
			from:{
				type:String,
				default:''
			},
			history:{
				type:String,
				default:''
			}
		},
		setup(){
			function goSearch(){
				console.log("fdfsd");
				this.$router.replace({path: '/search',query:{history:this.history}});
			}
			function gohistory() {
				debugger;
				if(this.from=='selectHuati'){
					this.$parent.closeFun();
				}else{
					if(this.history){
						this.$router.replace(this.history);
					}else
					this.$router.go(-1);
				}				
			}
			return{
				goSearch,
				gohistory
			}
		}
	});
</script>

<style lang="less" scoped="scoped">
	.topBox{
		height:0.88rem;
		position: relative;
		z-index:999;
	}
	.topDiv {
		height: 0.88rem;
		background: linear-gradient(0deg, #95C5B5, #64A4A4);
		position: relative;
		padding: 0.29rem 0.24rem;
		text-align: left;
		position:fixed;
		top:0;
		left:0;
		right:0;
		display: flex;
		align-items: center;
		.searchBtn{
			position: absolute;
			padding: 0.13rem;
			bottom: 0.1rem;
			right: 0.1rem;
			width: 0.6rem;
			height: 0.6rem;
			// display: none;
		}
	}

	.topBox span {
		color: #fff;
		font-size: 0.32rem;
	}

	.backBtn {
		position: relative;
		width: 0.33rem;
		height: 0.31rem;
		left: 0.24rem;
		top: 0;
		margin-right: 0.4rem;
		img{
			max-width: 100%;
		}
	}
</style>
